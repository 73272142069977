hr.dotted {
  border: none;
  border-top: 1px dashed #ccc;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 500px;
  display: block;
  margin-left: 0;
}

.userName {
  color: #333333;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: bold;
}

.passwordChange {
  min-height: 70vh;
}
