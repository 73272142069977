header {
  padding-top: 15px;
  padding-bottom: 15px;

  /* Media ($sm)
  -------------------------------------------------- */
  @media screen and (max-width: $sm) {
    &.fixedPadding {
      margin-top: 50px;
    }
  }


  .logo {
    width: 240px;

    img {
      width: 100%;
      height: auto;
    }
  }

  /* Media ($md)
-------------------------------------------------- */


  .rightNav {

    @media screen and (max-width: $md) {
      position: absolute;
      right: 0;
      width: 50%;
      overflow: hidden;
    }

    a {
      font-size: $sm-text;
      font-weight: 400;

      &+a {
        &:before {
          content: "|";
          margin-left: 5px;
          margin-right: 5px;
          color: $text;

        }
      }
    }

    .hamburger {
      display: none;
      width: 24px;
      height: 18px;
      position: absolute;
      right: 15px;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      &.active {
        span:first-child {
          transform: translateY(calc(50% + 7px)) rotate(45deg);
        }

        span:nth-child(2) {
          width: 0;
        }

        span:last-child {
          transform: translateY(calc(50% - 7px)) rotate(-45deg);

        }
      }

      span {
        position: relative;
        transition: 0.3s ease-in-out;
        height: 4px;
        border-radius: 1px;
        width: 100%;
        background-color: $grey-dk;
      }
    }

    /* Media ($md)
    -------------------------------------------------- */
    @media screen and (max-width: $md) {
      position: absolute;
      right: 0;
      width: 50%;
      z-index: 3;

      .hamburger {
        display: flex;
        z-index: 2;
        cursor: pointer;
      }

      // &:after {
      //   content: "\f0c9";
      //   font-family: $fontawesome;
      //   font-weight: 900;
      //   position: absolute;
      //   right: 15px;
      //   top: 0;
      // }
      .menu-items {
        background-color: $white;
        transition: 0.3s ease-in-out;
        transform: translateX(calc(100% + 15px));
        padding-top: 16px;
        z-index: 1;

        &.active {
          transform: translateX(15px);
        }

        a {
          display: block;
          padding: 15px 0;
          text-align: center;

          &:before {
            display: none;
          }
        }
      }
    }
  }
}