@import './fontawesome/brands.scss';
@import './fontawesome/fontawesome.scss';
@import './fontawesome/regular.scss';
@import './fontawesome/solid.scss';
@import './fontawesome/v4-shims.scss';

@import url('https://fonts.googleapis.com/css?family=Open+Sans|Montserrat:400,700,800|Roboto:300,400,700&display=swap');

$montserrat: 'Montserrat',
sans-serif;

$opensans: 'Open Sans',
sans-serif;

$roboto: 'Roboto',
sans-serif;

$fontawesome: 'Font Awesome 5 Free';