.Payment {
  .preFillCheckbox {
    margin-bottom: 1em;
    display: flex;
    align-items: center;
  }

  .paymentAddress {
    .FieldWrapper {

      input,
      select {
        font-weight: 400;
        color: $primary;
      }

      input::placeholder {
        font-weight: 300;
      }

      &:after {
        color: $primary;
      }
    }
  }

  .paymentMethod {
    width: 320px;

    button.btn.btn-radioButtons[name="paymentMethod"]:first-child {}

    .form-group {
      .FieldWrapper {
        input:not([name="ccv"]) {
          width: 100%;
        }
      }

      [data-id="bankNumber"] input[name="bankNumber"] {
        width: 50%;
      }

      // [data-id="bankNumber"],
      // [data-id="transitNumber"],
      [data-id="accountNumber"],
      [data-id="nameOnCard"],
      [data-id="creditCardNum"] {
        width: 100%;

        .FieldWrapper {
          width: 100%;
        }
      }

      [data-id="cardExpiryMM"],
      [data-id="cardExpiryYY"] {
        width: 33%;
        display: inline-block;

        .FieldWrapper {
          width: calc(100% - 25px);

          select {
            width: 100%;
          }
        }
      }

      [data-id="ccv"] {
        width: 33%;
        display: inline-block;

        .FieldWrapper {
          width: 100%;

          input {
            width: 100%;
          }
        }
      }
    }
  }
}