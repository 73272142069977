@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes ellipse {
  0% {
    content: "";
  }

  25% {
    content: ".";
  }

  50% {
    content: "..";
  }

  75% {
    content: "...";
  }

  100% {
    content: "";
  }
}

.loading {
  color: $primary;
  font-size: 20px;
  text-align: center;

  &__text {
    font-weight: bold;

    &:after {
      content: "";
      position: absolute;
      animation: ellipse 3s infinite;
      animation-timing-function: linear;
    }
  }

  &__icon {
    animation: spin 1s infinite;
    animation-timing-function: linear;
    width: 20px;
    margin: 0 auto;

    &:after {
      color: $primary;
      content: "\f1ce";
      font-weight: 900;
      font-family: $fontawesome;
    }
  }
}

.failedLoad {
  font-size: 20px;
  color: $alert;
  font-weight: bold;
}