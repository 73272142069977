.hasToolTip {
  display: inline;
}

.ToolTip {
  display: inline-block;
  margin-left: 5px;
  position: relative;

  /* Media ($md)
  -------------------------------------------------- */
  @media screen and (max-width: $md) {
    position: static;
  }

  .ToolTipToggle {
    cursor: pointer;
  }

  $tooltipPadding: 15px;

  .ToolTipText {
    font-family: $roboto;
    font-size: $sm-text;
    transition: all .4s;
    position: absolute;
    width: 300px;
    opacity: 0;
    pointer-events: none;
    padding: $tooltipPadding;
    box-sizing: border-box;
    border: 1px solid $grey-dk;
    margin-top: 15px;
    background-color: $white;
    z-index: 1;

    /* Media ($md)
      -------------------------------------------------- */
    @media screen and (max-width: $md) {
      position: absolute;
      top: auto;
      left: 0;
      right: 0;
      bottom: auto;
      height: auto;
      margin: 0 auto;
    }

    &Close {
      cursor: pointer;
      font-size: 18px;
      border-radius: 100%;
      background-color: $white;
      position: absolute;
      top: -10px;
      right: -10px;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary;
    }

    &.active {
      opacity: 1;
      pointer-events: all;
    }

    &:before {
      content: "";
      width: round($tooltipPadding * $root2);
      height: round($tooltipPadding * $root2);
      display: block;
      position: absolute;
      background-color: $white;
      border-left: 1px solid $grey-dk;
      border-bottom: 1px solid $grey-dk;

      /* Media ($md)
      -------------------------------------------------- */
      @media screen and (max-width: $md) {
        display: none;
      }
    }

    &[data-primary_origin="vertical"] {
      &[data-vertical_origin="top"] {
        top: round($tooltipPadding);

        @media screen and (max-width: $md) {
          top: unset;
        }

        &[data-horizontal_origin="left"] {
          left: round(-$tooltipPadding * 2);

          @media screen and (max-width: $md) {
            left: 0;
          }

          &:before {
            top: round(-$tooltipPadding/$root2);
            transform: rotate(135deg);
            left: ($tooltipPadding * 2) - 5px;
          }
        }

        &[data-horizontal_origin="right"] {
          right: round(-$tooltipPadding * 2);

          @media screen and (max-width: $md) {
            right: 0;
          }

          &:before {
            top: round(-$tooltipPadding/$root2);
            transform: rotate(135deg);
            right: round(($tooltipPadding * 2) - 5px);
          }
        }
      }

      &[data-vertical_origin="bottom"] {
        bottom: round($tooltipPadding * 2);

        @media screen and (max-width: $md) {
          bottom: unset;
        }

        &[data-horizontal_origin="left"] {
          left: round(-$tooltipPadding * 2);

          @media screen and (max-width: $md) {
            left: 0;
          }

          &:before {
            bottom: round(-$tooltipPadding/$root2);
            transform: rotate(315deg);
            left: round(($tooltipPadding * 2) - 5px);
          }
        }

        &[data-horizontal_origin="right"] {
          right: round(-$tooltipPadding * 2);

          @media screen and (max-width: $md) {
            right: 0;
          }

          &:before {
            bottom: round(-$tooltipPadding/$root2);
            transform: rotate(315deg);
            right: round(($tooltipPadding * 2) - 5px);
          }
        }
      }
    }

    &[data-primary_origin="horizontal"] {
      &[data-horizontal_origin="left"] {
        left: round($tooltipPadding * 2);

        @media screen and (max-width: $md) {
          left: 0;
        }

        &[data-vertical_origin="top"] {
          top: round(-$tooltipPadding*2);

          @media screen and (max-width: $md) {
            top: unset;
          }

          &:before {
            top: round(($tooltipPadding) - 5px);
            transform: rotate(45deg);
            left: round(-$tooltipPadding/$root2);
          }
        }

        &[data-vertical_origin="bottom"] {
          bottom: round(-$tooltipPadding);

          @media screen and (max-width: $md) {
            bottom: unset;
          }

          &:before {
            bottom: round(($tooltipPadding) - 5px);
            transform: rotate(45deg);
            left: round(-$tooltipPadding/$root2);
          }
        }
      }

      &[data-horizontal_origin="right"] {
        right: round($tooltipPadding * 2);

        @media screen and (max-width: $md) {
          right: 0;
        }

        &[data-vertical_origin="top"] {
          top: round(-$tooltipPadding *2);

          @media screen and (max-width: $md) {
            top: unset;
          }

          &:before {
            top: round(($tooltipPadding) - 5px);
            transform: rotate(225deg);
            right: round(-$tooltipPadding/$root2);
          }
        }

        &[data-vertical_origin="bottom"] {
          bottom: round(-$tooltipPadding);

          &:before {
            bottom: round(($tooltipPadding) - 5px);
            transform: rotate(225deg);
            right: round(-$tooltipPadding/$root2);
          }
        }
      }

    }
  }
}