.menu-account-sidebar {
  margin: 0;
  margin-top: 1em;
  border: 1px solid $primary-color;
  border-top-width: 10px;
  list-style: none;
  padding: 1em;


  li {
    list-style: none;

    &:before {
      display: none;
    }

    a {
      color: $text;
    }

    &.active {
      a {
        text-decoration: none;
        font-weight: 700;
      }
    }
  }
}