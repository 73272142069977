.nightmode {
  filter: invert(0.85);
}

body,
html {
  background-color: #f2f2f2;
}

/* Media ($lg)
-------------------------------------------------- */
@media screen and (min-width: $lg) {
  body {
    padding: 20px;
  }
}


.wrap {
  background-color: $white;
  border: 1px solid $grey-lt;
}