.formWrapper {
  max-width: 380px;
  width: 100%;
  margin: 0 auto;
}

.Form {
  background-color: $form-bg;
  padding: 25px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  .formLink {
    color: $text;
  }

  h1,
  h2 {
    margin: 0;
  }

  p {
    font-size: $sm-text;
  }

  form {
    .form-group {
      margin-top: 20px;

      label {
        display: block;
      }

      input {
        appearance: none;
        border: none;
        outline: none;
        padding: 3px 2px;
        font-family: $roboto;
        height: 30px;
        box-sizing: border-box;
        max-width: 220px;
        width: 100%;
        margin-top: 5px;

        & + label {
          margin-top: 10px;
        }
      }
    }

    button[type='submit'] {
      margin-top: 20px;
    }
  }
}

$form_margin: 20px;
$input_height: 30px;

.Line {
  border-width: 0;
  border-style: dotted;

  &.Green {
    border-color: $primary;
  }

  &.Grey,
  &.Gray {
    border-color: $grey-lt;
  }

  &.bottom {
    margin-bottom: $form_margin;
    border-bottom-width: 1px;

    &:last-child {
      margin-bottom: $form_margin;
    }
  }

  &.top {
    margin-top: $form_margin;
    border-top-width: 1px;
    padding-top: $form_margin * 2;

    &.p10 {
      padding-top: 10px;
    }

    & > :first-child {
      // margin-top: $form_margin;
    }
  }
}

.formDescription {
  margin-bottom: $form_margin;
}

.PrintFormGroup {
  .form-group {
    /* Media ($sm)
  -------------------------------------------------- */
    @media screen and (max-width: $sm) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &.conditional {
      display: block;
    }

    margin-bottom: $form_margin;

    .description {
      margin-bottom: $form_margin;
    }

    label {
      display: block;
      margin-bottom: 10px;
      font-size: $sm-text;
      font-family: $roboto;
      font-weight: 300;
    }

    button.btn {
      text-transform: capitalize;
      padding: 6px 25px;
    }

    input,
    select,
    textarea,
    button {
      border: 1px solid $grey-lt;
      // margin-bottom: $form_margin;
      outline: none;
    }

    &[data-id^='addressLine'] {
      input {
        width: 270px;
      }
    }

    &[data-id='addressLine1'] {
      margin-bottom: 10px;
    }

    &[data-id='city'],
    &[data-id='province'] {
      display: inline-block;

      /* Media ($md)
      -------------------------------------------------- */
      @media screen and (max-width: $md) {
        display: block;
      }
    }

    &[data-id='city'] {
      input {
        width: 215px;
      }
    }

    &[data-id='postal'] {
      input {
        width: 100px;
      }
    }

    &[data-id='province'] {
      margin-left: 25px;

      /* Media ($md)
      -------------------------------------------------- */
      @media screen and (max-width: $md) {
        margin-left: 0;
      }
    }

    .radioWrap {
      margin-bottom: $form_margin;
    }
  }
}

.form-group {
  margin-bottom: $form_margin;

  .description {
    color: $text;
  }

  &.checkList {
    display: flex;
    flex-direction: row;
    margin: 5px 0;
    color: $text;

    .form-check-input {
      appearance: none;
      height: 12px;
      width: 12px;
      margin-right: 5px;
      border-color: $gray-dk;
      display: flex;
      align-items: center;
      justify-content: center;

      &:checked {
        &:before {
          font-family: $fontawesome;
          content: '\f00c';
          font-weight: 900;
          font-size: 8px;
          color: $text;
        }
      }
    }
  }

  .form-label {
    font-family: $roboto;
    font-size: $sm-text;
  }

  &.conditional {
    display: none;

    &.active {
      display: block;
    }
  }

  .FieldWrapper {
    display: inline-block;
    position: relative;

    &.textarea {
      display: block;

      textarea {
        resize: none;
        width: 100%;
      }
    }

    &.buttons {
      display: block;

      // margin: 20px 0;
    }

    input {
      height: $input_height;
    }

    select {
      appearance: none;
      outline: 0;
      border-radius: 0;
      background-color: $white;
      padding-right: $input_height;
      padding-left: 10px;
      height: $input_height;
      border: 1px solid $grey-lt;
      position: relative;
      color: $text;
      cursor: pointer;
      display: inline-block;

      /* Media ($tablet)
      -------------------------------------------------- */
      @media screen and (max-width: $md) {
        display: block;
        width: 100%;
        border: none;
        font-size: 17px;
        color: $primary;
        padding: 0;
      }
    }

    textarea {
      border: 1px solid $grey-lt;
    }

    &.select:after {
      content: '\f0dc';
      color: $text;
      font-family: $fontawesome;
      font-weight: 900;
      display: flex;
      justify-content: center;
      align-items: center;
      height: $input_height;
      width: $input_height;
      position: absolute;
      right: 0;
      top: 0;
      pointer-events: none;

      /* Media ($md)
      -------------------------------------------------- */
      @media screen and (max-width: $md) {
        display: none;
      }
    }
  }

  .checkboxDependency {
    .checkboxDescription {
      margin-bottom: 5px;
    }
  }

  .radios .FieldWrapper{
      display: flex;
      label{
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 2px 20px;
      }
  }

  .checkboxWrapper,
  .toggle.FieldWrapper > .form-group {
    display: flex;
    align-items: center;

    .checkboxStyle,
    .toggleStyle {
      width: 36px;
      height: 36px;
      min-width: 36px;
      min-height: 36px;
      display: inline-block;
      border-radius: 100%;
      border: 3px solid $black;
      margin-right: 20px;

      & + .form-check {
        display: none;
      }
    }

    .form-label {
      // color: $black;
      font-size: $base-text-size;
      font-weight: 700;
    }

    &[data-checked='true'],
    &.toggle.active {
      label.form-label {
        color: $primary;
        border-color: $primary;

        &.checkboxStyle,
        &.toggleStyle {
          font-family: $fontawesome;
          font-weight: 900;

          &:before {
            content: '\f00c';
            font-size: 20px;
            line-height: 30px;
            width: 30px;
            text-align: center;
            display: inline-block;
          }
        }
      }
    }
  }

  .form-group.buttons {
    margin: 0px 0 20px;
  }

  // input[type="checkbox"] {
  //   display: none;
  // }

  .btn-group {
    .btn {
      padding: 6px 32px;
      background-color: $form-bg;
      font-weight: 300;
      font-size: $sm-text;
      border-top: 1px solid $grey-lt;
      border-bottom: 1px solid $grey-lt;
      border-left: 1px solid $grey-lt;

      /* Media ($md)
      -------------------------------------------------- */
      @media screen and (max-width: $md) {
        font-size: 10px;
      }

      &:hover {
        color: $primary;
      }

      &.active,
      &:active {
        color: $white;
        background-color: $primary;
        font-weight: bold;
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-right: 1px solid $grey-lt;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}

input[type='checkbox'] {
  outline: none;
  appearance: none;
  height: 12px;
  width: 12px;
  margin-right: 5px;
  border: 1px solid $gray-dk;
  display: flex;
  align-items: center;
  justify-content: center;

  &:checked {
    &:before {
      font-family: $fontawesome;
      content: '\f00c';
      font-weight: 900;
      font-size: 8px;
      color: $text;
    }
  }
}
.cyberDisclaimer {
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 56px;
}
