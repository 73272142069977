.Licensing {
  .licensingFields {
    .tab>.form-group {
      margin-left: 20px;
    }

    .PrintFormGroup {
      &>.form-group {
        margin-bottom: 15px;
      }
    }

    .form-group {
      &.conditional {
        color: $black;
        margin-left: 50px;
        margin-top: 10px;


        label {
          &.form-label {
            &.toggleStyle:before {
              font-size: 14px;
              line-height: 20px;
              width: 18px;

            }
          }
        }

        .checkList {
          align-items: center;
          font-size: $sm-text;

          label {
            margin-bottom: 0;
          }

          input {
            // height: auto;
          }
        }
      }
    }

    [data-id="other"] {
      display: flex;

      .conditional {
        margin-left: 10px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .form-group[data-parent^="licensing"] {
      margin-bottom: 5px;

      .toggle {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        flex-direction: row;

        .toggleStyle {
          width: 24px;
          height: 24px;
          min-width: 24px;
          min-height: 24px;
          display: inline-block;
          border-radius: 100%;
          border: 2px solid $text;
          margin-right: 20px;

          &+.form-check {
            display: none;
          }
        }

        .form-label {
          color: $text;
          font-family: $montserrat;
          font-size: $base-text-size;
          font-weight: 700;
        }
      }

      >.toggle {
        .active {
          label.form-label {
            color: $primary;
            border-color: $primary;

            &.toggleStyle {
              font-family: $fontawesome;
              font-weight: 900;

              &:before {
                content: "\f00c";
                font-size: 14px;
                line-height: 22px;
                width: 20px;
                text-align: center;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}