.btn {
  line-height: 18px;
  font-size: $base-text-size;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  appearance: none;
  outline: none;
  border: none;
  font-family: $roboto;
  font-weight: 700;
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
}

.btn.disabled {
  cursor: not-allowed;
}

.btn-primary {
  &:not(.disabled) {
    @include hover($primary);
  }

  color: $white;
  border-radius: 25px;
  padding: 11px 29px;

  &.ghost {
    color: $primary;
    border: 1px solid $primary;
    background-color: $white;
    @include hover($white, $form-bg);
  }

  &.mobile-circle {

    /* Media ($sm)
    -------------------------------------------------- */
    @media screen and (max-width: $sm) {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      padding: 0;
      text-align: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      font-size: 18px;
    }
  }
}
.btn-danger, .btn-red, .btn-alert {
  &:not(.disabled) {
    @include hover($alert);
  }

  color: $white;
  border-radius: 25px;
  padding: 11px 29px;

  &.ghost {
    color: $alert;
    border: 1px solid $alert;
    background-color: $white;
    @include hover($white, $form-bg);
  }

  &.mobile-circle {

    /* Media ($sm)
    -------------------------------------------------- */
    @media screen and (max-width: $sm) {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      padding: 0;
      text-align: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      font-size: 18px;
    }
  }
}

.btn-secondary {
  background-color: transparent;
  padding: 13px 20px;
  color: $white;
  font-family: $montserrat;
  font-size: $base-text-size;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;

  &.black {
    color: $black;

    &:after,
    &:before {
      border-color: $black;
    }
  }

  &:after,
  &:before {
    transition: 0.3s ease-in-out;
    content: "";
    width: 18px;
    height: 18px;
    background-color: transparent;
    border: 5px solid $white;
    position: absolute;
  }

  &:after {
    border-left: none;
    border-bottom: none;
    right: 0;
    top: 0;
  }

  &:before {
    border-right: none;
    border-top: none;
    bottom: 0;
    left: 0;
  }

  &:hover {
    &:not([disabled]):before {
      width: 100%;
      height: 100%;
    }

    &:not([disabled]):after {
      width: 100%;
      height: 100%;
    }
  }
}

.btn-tertiary {
  padding: 4px 8px;
  min-width: 65px;
  font-size: 10px;
  line-height: 14px;
  font-weight: normal;
  text-transform: uppercase;
  background-color: $white;
  color: $tertiary;
  text-align: center;
  border-radius: 5px;
  @include hover($white, $form-bg);

  &.edit,
  &.success {
    border: 1px solid $primary;
    color: $primary;
    @include hover($white);
  }

  &.alert {
    padding: 4px 15px;
    border: 1px solid $alert;
    color: $alert;
    @include hover($white);
  }
}