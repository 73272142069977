body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include bodyFont;
}

h1,
.h1 {
  @include h1Tag;
}

h2,
.h2 {
  @include h2Tag;
}

h3,
.h3 {
  @include h3Tag;
}

h4,
.h4 {
  font-family: $montserrat;
  font-size: $base-text-size;
  margin: 12px 0;
}

a {
  @include aTag;
}



.text-grey,
.text-gray {
  color: $grey-md;
}

.text-primary {
  color: $primary-color;
}

.text-sm {
  font-size: $sm-text;
}