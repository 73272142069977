.sideBar {
  .PolicyDetails {

    /* Media ($lg)
    -------------------------------------------------- */
    @media screen and (max-width: $lg) {
      display: none;
    }

    &__mobileHeader {
      display: none;
    }
  }
}

.mobilePricing {

  .PolicyDetails {
    overflow: hidden;
    z-index: 3;
    width: 40%;
    height: auto;
    max-width: 280px;
    position: relative;
    display: none;
    padding: 0;
    transition: max-height 0.3s ease-in-out;

    &.active {
      .PolicyDetails__mobileHeader {
        &:after {
          content: "\f077";
        }
      }
    }

    .PolicyDetails__mobileHeader {
      border-top-left-radius: 5px;

      /* Media ($sm)
      -------------------------------------------------- */
      @media screen and (max-width: $sm) {
        border-radius: 0;
      }
    }

    .PolicyDetails__Wrapper {
      padding: 15px;

      background-color: $white;

      /* Media ($lg)
    -------------------------------------------------- */
      @media screen and (max-width: $lg) {
        // transform: translateY(calc(0% + 50px));
        z-index: 4;
      }
    }

    /* Media ($lg)
    -------------------------------------------------- */
    @media screen and (max-width: $lg) {
      display: block;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      position: absolute;
      top: calc(50% - 25px);
      right: calc((-1) * ((100vw - 100%) / 2));
      max-height: 50px;

      .PolicyDetails__Wrapper {
        margin-top: 50px;
      }

      &.active {
        max-height: 900px;
      }
    }

    /* Media ($sm)
    -------------------------------------------------- */
    @media screen and (max-width: $sm) {
      margin-right: 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;

      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
    }

    &.active {
      // overflow: visible;
      // position: absolute;
      // height: auto;
      // right: 0;
      // top: 0;
      // padding-bottom: calc(100% + 50px);
      z-index: 4;
      background-color: $white;
    }

    &__mobileHeader {
      height: 50px;
      width: 100%;
      background-color: $primary;
      z-index: 4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      right: 0;
      padding: 0 25px 0 15px;
      color: $white;
      font-weight: bold;

      @media screen and (min-width: $lg) {
        display: none;
      }

      &:after {
        font-family: $fontawesome;
        content: "\f078";
        font-size: 12px;
        font-weight: 900;
        color: $white;
        border-right: 1px solid $white;
        padding-right: 10px;
        height: 60%;
        display: flex;
        align-items: center;

        /* Media ($sm)
        -------------------------------------------------- */
        @media screen and (max-width: $sm) {
          border: none;
        }
      }

      .price {

        /* Media ($sm)
        -------------------------------------------------- */
        @media screen and (max-width: $sm) {
          margin-left: auto;
          margin-right: 50px;
        }
      }
    }

    &__Wrapper {

      /* Media ($lg)
    -------------------------------------------------- */
      @media screen and (max-width: $lg) {
        // transform: translateY(-100%);
        z-index: 4;
      }
    }
  }
}

.PolicyDetails {
  width: 380px;

  margin-bottom: 25px;
  padding: 10px;
  background-color: rgba($primary, .15);
  font-size: $sm-text;

  /* Media ($lg)
  -------------------------------------------------- */
  @media screen and (min-width: $lg) {
    width: calc(100% + (100vw - 40px - 1010px)/2);
    max-width: 375px;
  }

  /* Media ($lg)
  -------------------------------------------------- */
  @media screen and (max-width: $lg) {
    display: none;
  }

  // $lg Ends ---->

  //  $lg Ends ---->
  &__header {
    color: $white;
    padding-bottom: 5px;
    border-bottom: 2px solid $primary;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    a {
      align-self: center;
    }

    h2 {
      margin: 0;
    }

  }

  &__items {

    .item {
      padding: 10px 0;
      border-bottom: 1px dotted $primary;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &__footer {
    border-top: 2px solid $primary;
    padding-top: 10px;

    .total {
      // font-weight: bold;
    }

    .message {
      font-weight: bold;
      margin-top: 3em;
    }
  }

  .number {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .alertText {
      font-weight: bold;
    }

    .label {}

    .value {
      text-align: right;
    }
  }

}