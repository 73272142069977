.Email {
  .QuoteSent {
    align-items: flex-end;

    .policy {
      margin-top: .8em;
    }
  }

  .emailCtas {
    margin-top: 50px;
    font-size: $sm-text;

    >div {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .ctaCopy {
        margin-bottom: 20px;
      }

      .btn {
        margin-top: auto;
      }
    }
  }
}