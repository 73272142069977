.reviewFields {
  margin: 1.5em 0;

  >.row {
    margin: 10px 0;
    justify-content: center;
    align-items: center;
    border-top: 1px dotted $grey-lt;
    padding-top: 10px;

    /* Media ($md)
    -------------------------------------------------- */
    @media screen and (max-width: $md) {
      position: relative;
      padding: 15px 0;
      margin: 0;

      &>.col-auto {
        position: absolute;
        top: 10px;
        right: 0;
      }

      &>[class*="Review"] {
        &>.row {
          margin-top: 15px;
          padding-bottom: 15px;
        }
      }

      .reviewTitle {
        font-style: italic;
      }
    }

    .quoteReview {
      .row {
        @media screen and (max-width: $md) {
          // padding: 0 0 15px;
          padding: 2.5px 0;
          font-size: 12px;
          border-bottom: none;
        }
      }
    }

    .coveragesReview {
      .row {
        padding: 5px 0;
        border-bottom: 1px dotted $grey-lt;

        /* Media ($md)
        -------------------------------------------------- */
        @media screen and (max-width: $md) {
          // padding: 0 0 15px;
          padding: 2.5px 0;
          font-size: 12px;
          border-bottom: none;
        }

        &:last-child {
          border: none;
        }
      }
    }

    .discountReview {
      .row {
        padding: 5px 0;
        position: relative;

        @media screen and (max-width: $md) {
          // padding: 0 0 15px;
          padding: 2.5px 0;
          font-size: 12px;
          border-bottom: none;
        }

        i {
          color: $primary;
          position: absolute;
          left: -20px;

          /* Media ($md)
          -------------------------------------------------- */
          @media screen and (max-width: $md) {
            position: relative;
            left: 0;
            margin-right: 5px;
            margin-left: 2px;
          }
        }
      }
    }
  }

  .amount {
    color: $alert;
  }

  .btn.edit {
    margin-left: 25px;
  }
}

.cta {
  background-color: $tertiary;
  color: $white;
  padding: 15px;
  align-items: center;

  /* Media ($md)
  -------------------------------------------------- */
  @media screen and (max-width: $md) {
    margin: 0 -15px;
    flex-direction: columns;

    >div {
      width: 100%;

      &.col-auto {
        text-align: center;
      }
    }
  }

  h4 {
    margin: 0 0 10px 0;
  }

  p {
    margin: 0;
  }
}