.FormTopNav {
  display: flex;
  margin-top: 45px;
  margin-bottom: 45px;
  margin-left: -20px;

  /* Media ($md)
  -------------------------------------------------- */
  @media screen and (max-width: $md) {
    margin-left: 0;
    position: relative;
    margin: 20px 0 50px;
    flex-direction: column;
  }

  .nav {
    display: flex;
    width: 95%;

    /* Media ($lg)
  -------------------------------------------------- */
    @media screen and (max-width: $md) {
      width: 100%;
      // position: absolute;
    }

    //  $lg Ends ---->


    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: calc((1/5)*100%);
      position: relative;

      .stepNum {
        display: flex;
        border: 5px solid $black;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        background-color: $white;
        text-decoration: none;
        z-index: 2;
      }

      .stepName {
        margin-top: 5px;
        font-size: 10px;
        font-weight: 400;
      }

      .stepBar {
        position: absolute;
        width: 100%;
        height: 5px;
        background-color: $black;
        top: 12.5px;
        left: -50%;
        z-index: 1;
      }

      &.inactive {
        .stepNum {
          border-color: $disabled;
        }

        .stepName {
          color: $disabled;
        }

        .stepBar {
          background-color: $disabled;
        }
      }

      &.complete {
        .stepNum {
          border-color: $success;
        }

        .stepName {
          color: $success;
        }

        .stepBar {
          background-color: $success;
        }
      }
    }
  }

  .message {
    width: 30%;
    font-family: $montserrat;
    font-size: 24px;
    display: flex;
    align-items: center;

    /* Media ($md)
    -------------------------------------------------- */
    @media screen and (max-width: $md) {
      width: auto;
      margin: 25px auto 0;
    }
  }
}