span.modal-trigger {
  color: $primary-color;
  cursor: pointer;
}

.modal-backdrop {
  animation-name: fadeIn;
  animation-duration: 0.1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($black, 0.7);
  z-index: 10;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .modal-dialog {
    background-color: $white;
    padding: 2em;
    border-radius: 1em;
    max-width: 27em;
    box-shadow: 0.15em 0.15em 0.75em 0.5em rgba($black, 0.2);

    .formDescription {
      margin-bottom: 1em;
    }

    .form-group {
      margin-bottom: 1em;

      .form-label {
        display: block;
        margin-bottom: 0.5em;
      }

      input {
        width: 100%;
        border-radius: 0.5em;
        border: 1px solid $grey-lt;
        padding: 0.5em 0.25em;
      }
    }
  }
}
