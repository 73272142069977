.Coverages {
  .PrintFormGroup .ToolTip {
    margin-bottom: 10px;

    .ToolTipText {
      color: $text;
      font-weight: 300;
    }
  }

  .coveragesFields,
  .cyberPrivacyFields {
    padding-left: 20px;

    /* Media ($md)
    -------------------------------------------------- */
    @media screen and (max-width: $md) {
      padding-left: 0;
    }

    & > .description {
      margin-left: -10px;

      p {
        margin-top: 0;
        margin-bottom: 25px;
      }
    }

    .toggle.form-group {
      margin-bottom: 0;

      .FieldWrapper {
        label {
          font-family: $montserrat;
        }
      }
    }

    .btn-group .btn {
      min-width: 19.75%;
      padding: 6px 0;

      &[name="lifeInsurancePrice"],
      &[name="iirocPrice"] {
        width: 16.6%;
        min-width: 16.6%;
        max-width: 16.6%;
      }
    }

    .conditional {
      margin-left: 55px;

      .checkList {
        margin: 0;
        align-items: center;
        margin-bottom: 5px;

        label {
          margin: 0;
        }
      }

      /* Media ($md)
      -------------------------------------------------- */
      @media screen and (max-width: $md) {
        margin-left: 0;
      }
    }
  }
  div[data-id="TextBox3"] {
    margin-left: 18px !important;
  }
  div[data-id="otherDesignations"] {
    display: flex !important;
    height: 15px;
    transform: translateY(-13px);
    flex-direction: column;

    .toggle {
      flex-direction: row !important;
      div[name="otherDesignations"] {
        height: 12px;
        & > .toggleStyle {
          height: 12px !important;
          width: 12px !important;
          min-height: 12px !important;
          min-width: 12px !important;
          border-radius: 0 !important;
          border-width: 1px !important;
          border-color: $gray-dk !important;
          margin-right: 5px !important;
          display: flex !important;
        }
        &.active > .toggleStyle {
          &:before {
            font-family: "Font Awesome 5 Free" !important;
            content: "\f00c" !important;
            color: $text !important;
            font-weight: 900 !important;
            font-size: 8px !important;
            line-height: 10px !important;
          }
        }
        & > label {
          font-weight: 300 !important;
          color: $text !important;
        }
      }
    }
  }
}
