@import "fonts/fonts";



@import "base/variables";
@import "base/mixins";
@import "base/reset";
@import "base/typography";
@import "base/globals";

@import "components/footer.scss";
@import "components/header.scss";
@import "components/FormTopNav";
@import "components/FormBottomNav";
@import "components/account-info";
@import "components/Coupon";
@import "components/ToolTip";
@import "components/modal";
@import "components/menu-account-sidebar";
@import "components/ConfirmEmail";
@import "components/LoginPrompt";
@import "components/pricing-details";
@import "components/policyCard";
@import "components/PolicyDetails";

@import "elements/form.scss";
@import "elements/tabs.scss";
@import "elements/buttons.scss";
@import "elements/temp.scss";
@import "elements/sideBar.scss";
@import "elements/loading.scss";

@import "pages/quote";
@import "pages/quote/discounts";
@import "pages/quote/coverages";
@import "pages/quote/review";
@import "pages/quote/email";

@import "pages/checkout/confirmation";
@import "pages/checkout/information";
@import "pages/checkout/licensing";
@import "pages/checkout/review";
@import "pages/checkout/payment";

@import "pages/landing";
@import "pages/login";
@import "pages/reset-password";
@import "pages/talk-to-broker";
@import "pages/thank-you";
