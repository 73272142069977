.modal-backdrop.coupon-backdrop {
  position: fixed;
  z-index: 3;

  /* Media ($lg)
  -------------------------------------------------- */
  @media screen and (min-width: $lg) {
    display: none;
  }
}

div.Coupon {
  background-color: $secondary;
  padding: 15px 10px;
  z-index: 2;

  h4 {
    color: #fff;
    font-family: $montserrat;
    font-weight: 800;
    font-size: 18px;
  }

  i.error {
    color: $alert;
    position: relative;
    pointer-events: none;
    left: -20px;
  }

  .invalidCode,
  .successCode {
    font-weight: bold;
    margin: 15px 0;
    color: #fff;

    i {
      margin-right: 5px;
    }
  }

  .btn-coupon {
    @include hover($white);
    color: $secondary;
    border-radius: 25px;
    padding: 11px 29px;
  }

  .form-group {
    margin-bottom: 15px;
    display: block;
    align-items: center;

    label {
      color: $white;
      display: block;
      margin-top: 15px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
    }

    input {
      color: $black;
      margin-top: 7px;
      height: 25px;
      width: 90%;
    }
  }

  .btn {
    font-family: $roboto;
    font-weight: 400;
  }

  /* Media ($lg)
  -------------------------------------------------- */
  @media screen and (max-width: $lg) {
    position: fixed;
    max-width: 100%;
    width: 320px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    transform: translateY(70%);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: transform 0.3s ease-in-out;

    h4 {
      display: flex;
      margin: 0;

      &:after {
        margin-left: auto;
        font-family: $fontawesome;
        content: "\f077";
        font-size: 24px;
        font-weight: 900;
        color: $white;
      }
    }

    &.active {
      transform: translateY(0%);
      z-index: 6;

      h4:after {
        content: "\f078";
      }
    }

    .fa-info-circle {
      display: none;
    }
  }

  /* Media ($sm)
  -------------------------------------------------- */
  @media screen and (max-width: $sm) {
    width: 100%;
    border-radius: 0;
    transform: translateY(72%);
  }
}