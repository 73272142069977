.sideBar {
  padding-top: 40px;

  /* Media ($lg)
      -------------------------------------------------- */
  @media screen and (min-width: $lg) {
    width: 280px;
    margin-left: 20px;
  }


  // &.policyDetails {
  //   @media screen and (min-width: $lg) {
  //     width: 380px;
  //     margin-left: 20px;
  //   }
  // }
}