.Landing {
  color: $black;

  .landingFormWrap {
    .landingForm {
      overflow: hidden;
      position: relative;
      height: 100%;
      align-content: flex-start;

      &:after {
        @include triangleBottomRight(160);
      }

      &:before {
        @include triangleTopLeft(80);
      }

      .Form {
        margin: 50px auto;
        background-color: rgba($white, 0.7);
        font-size: $sm-text;
        z-index: 1;

        ul {
          margin-bottom: 25px;
        }

        h1 {
          margin-bottom: 25px;
        }
        .form-label {
          margin-bottom: 5px;
        }
        input {
          width: 100%;
          max-width: 100%;
          border: 1px solid $grey-lt;
        }
      }
    }
  }

  .landingTextWrap {
    .landingText {
      margin: 75px auto;

      .btn.btn-secondary {
        // font-size: 10px;
        // padding: 5px 20px;
        // margin-left: 10px;

        // &:before,
        // &:after {
        //   border-width: 4px;
        // }
        margin-bottom: 15px;
      }
    }
  }
  .nameFields{
    .form-group{
      margin-bottom: 0;
    }
  }
}
