$lg: 1199.98px;
$md: 991.98px;
$sm: 767.98px;
$xs: 575.98px;

$primary-color: #00a664;
$primary: $primary-color;
$success: $primary-color;

//hex on designs:  027db4
//hex in style guide: 7da7c0
$secondary-color: #009ade;
$secondary: $secondary-color;

$tertiary-color: #015478;
$tertiary: $tertiary-color;

$alert: #A30014;

$white: #ffffff;
$black: #000000;


$footer-lt: #1a1b21;
$footer-dk: #121316;

$gray-lt: #D7D7D7;
$gray-md: #AAAAAA;
$gray-dk: #7F7F7F;
//O Canada
$grey-lt: $gray-lt;
$grey-md: $gray-md;
$grey-dk: $gray-dk;

$text: #333333;
$form-bg: #f2f2f2;
$disabled: $gray-md;

$base-text-size: 14px;
$sm-text: 13px;
$base-h1-size: 32px;
$base-h2-size: 24px;
$base-h3-size: 18px;

$root2: 1.414213562373095;
