.greenBox {
  position: relative;
  overflow: hidden;
  border: 1px solid $primary;
  margin-bottom: 50px;
  padding: 10px;

  h3 {
    margin: 0;
  }
}

.grayBox {
  position: relative;
  overflow: hidden;
  border: 1px solid $text;
  margin-bottom: 50px;
  padding: 10px;

  h3 {
    margin: 0;
  }
}


.greenBox {
  &.triangle {
    &:after {
      @include triangleBottomRight(70);
    }
  }
}


.TalkToBroker {
  .left {
    .greenBox {
      max-width: 70%;

      .importantEmail {
        color: $text;
      }
    }
  }

  .right {
    form {

      input,
      select,
      textarea {
        font-weight: 400;
        color: $primary;
      }

      input::placeholder {
        font-weight: 300;
      }

      &:after {
        color: $primary;
      }
    }
  }
}