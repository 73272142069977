.Login {
  .Form {

    h1,
    h2 {
      color: $text;
    }

    &:after {
      @include triangleBottomRight(100);
    }
  }

  .resetPassword {
    @include aTag;
    font-weight: bold;
    margin: 15px 0 0 25px;
  }
}