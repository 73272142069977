.GetQuote {
  .error {
    color:#A30014;
  }
  .form-group {
    display: flex;
    align-items: center;

    /* Media ($sm)
    -------------------------------------------------- */
    @media screen and (max-width: $sm) {
      align-items: flex-start;

      // .FieldWrapper {
  //   position: relative;

  //   &.date {

  //     &:after {
  //       pointer-events: none;
  //       content: "Select a date...";
  //       position: absolute;
  //       height: 30px;
  //       font-size: 17px;
  //       display: block;
  //       color: $primary;
  //       left: 0;
  //       top: 0;
  //     }

  //     input {
  //       border: none;
  //     }
  //   }
  // }
    }

    label.form-label {
      width: 40%;

      /* Media ($md)
  -------------------------------------------------- */
      @media screen and (max-width: $sm) {
        width: 100%;
      }
    }

    input[name="FirstName"],
    input[name="Last"],
    input[name="startDate"] {
      width: 180px;
    }

    input[name="email"] {
      width: 220px;
    }
  }
}

::-webkit-inner-spin-button {
  display: none;
}

::-webkit-calendar-picker-indicator {
  color: rgba($black, 0);
  opacity: 1;
  display: block;
  background-repeat: no-repeat;
  background-position: center;

  /* Media ($sm)
  -------------------------------------------------- */
  // @media screen and (max-width: $sm) {
  //   background-image: none;
  //   width: 100%;
  //   background-color: transparent;
  //   height: 100%;
  //   background-color: black;
  //   position: absolute;
  //   left: 0;
  // }

  &:hover {
    background-color: transparent;
  }
}
