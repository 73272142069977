footer {
  color: $gray-lt;

  .footer__btn__broker {
    margin-left: 15px;
  }
  .logo_PMA img {
    height: 50px;
    width: auto;
  }

  .upperFooter {
    background-color: $footer-lt;
    padding-top: 25px;
    padding-bottom: 25px;
    justify-content: center;

    .accountBtn {
      margin-top: 25px;
      width: calc(100% - 5px);
      text-align: center;
    }
  }

  .logo {
    width: 240px;

    /* Media ($sm)
    -------------------------------------------------- */
    @media screen and (max-width: $sm) {
      padding-bottom: 20px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .lowerFooter {
    background-color: #323e48;
    border-color: #4b4c4d;
    border-top-width: 0px;
    padding-top: 15px;
    padding-bottom: 15px;

    &.fixedPadding {

      /* Media ($lg)
    -------------------------------------------------- */
      @media screen and (max-width: $lg) {
        padding-bottom: 60px;
      }
    }

    .social-icons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .social-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        padding: 0;
        text-align: center;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        font-size: 18px;
      }

      .social-icon+.social-icon {
        margin-left: 15px;
      }
    }
  }
}
