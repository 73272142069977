@keyframes question_todo {
  0% {
    background-color: rgba($alert, 0.2);
  }

  25% {
    background-color: rgba($alert, 0.4);
  }

  50% {
    background-color: rgba($alert, 0.6);
  }

  75% {
    background-color: rgba($alert, 0.4);
  }

  100% {
    background-color: rgba($alert, 0.2);
  }
}
.z-10{
  z-index: 10;
}
.question_todo {
  animation: question_todo 3s infinite;
  animation-timing-function: linear;
}

.container {
  max-width: 1010px;

  // /* Media ($lg)
  // -------------------------------------------------- */
  // @media screen and (min-width: $lg) {
  //   padding: 0;
  // }
}

html,
body,
#root {
  height: 100%;
}
.wrap {
  max-width: 1200px;
  margin: 0 auto;
}

[disabled],
.disabled {
  filter: grayscale(75%);
  color: $disabled !important;

  &:before,
  &:after {
    border-color: $disabled !important;
  }
}

.sep {
  margin: 0 5px;
}

.bold {
  font-weight: 700;
}

.underline {
  text-decoration: underline;
}

.greenText {
  color: $primary;
}

.italic {
  font-style: italic;
}

.link {
  color: $primary;
  text-decoration: underline;
}

.chevron {
  &:after {
    @include chevron_right($primary, 20px);
    margin-left: 10px;
  }
}

.alertText {
  color: $alert;
}

.successText {
  color: $success;
}

.mobile {
  display: none;

  /* Media ($sm)
  -------------------------------------------------- */
  @media screen and (max-width: $sm) {
    display: block;
  }
}

.desktop {
  display: block;

  /* Media ($sm)
  -------------------------------------------------- */
  @media screen and (max-width: $sm) {
    display: none;
  }
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
  list-style: none;

  li {
    margin-bottom: 10px;
    position: relative;

    &:before {
      @include chevron_right($primary, 12px);
      position: absolute;
      left: -1.15em;
      top: 2px;
    }
  }
}
.px-2{
  padding-left: .25rem;
  padding-right: .25rem;
}
.whitespace-nowrap{
  white-space: nowrap;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.mt0,
.mt-0 {
  margin-top: 0;
}
.spinning {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
