.Information {
  // .flaggedUser {
  //   .LoaderButton {
  //     background-color: rgba($alert, 0.7);

  //     &:hover {
  //       background-color: rgba($alert, 0.5);
  //     }
  //   }
  // }

  .companies {
    margin-bottom: 25px;

    .form-group {
      margin: 0;

      &.checkList {
        align-items: center;
        margin-bottom: 10px;

        .form-label {
          margin: 0;
        }

        i {
          margin-left: 5px;
          font-size: 11px;
          color: $gray-dk;
        }
      }
    }

    textarea {
      resize: none;
      width: 415px;
      max-width: 100%;
    }

    .selectedCompanies {
      &__description {
        margin-top: 15px;
        margin-bottom: 10px;
      }

    }
  }

  .form-group[data-id="legalName"] .FieldWrapper {
    width: 75%;
  }

  .form-group[data-id="legalName"] .FieldWrapper input {
    width: 100%;
  }
}