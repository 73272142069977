.tabWrapper {
  &__labels {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid $disabled;
    margin-bottom: 15px;

    .label {
      margin-left: 10px;
      padding: 12px 40px;
      border: 1px solid $disabled;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin-bottom: -1px;
      border-bottom: none;
      color: $disabled;
      cursor: pointer;
      font-size: 12px;

      /* Media ($md)
      -------------------------------------------------- */
      @media screen and (max-width: $md) {
        font-size: 10px;
        padding: 10px 15px;
        margin-left: 5px;
        max-width: 45%;
      }

      +.label {
        margin-left: 15px;

        @media screen and (max-width: $md) {
          margin-left: 10px;
        }
      }

      &:hover {
        color: $text;
      }

      &.active {
        border-color: $primary;
        color: $text;

        background-color: $white;
      }
    }
  }

  &__tabs {
    .tab {
      margin-left: 15px;
      display: none;

      &.active {
        display: block;
      }
    }
  }
}