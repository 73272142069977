@mixin chevron_right ($color: $primary, $size: 14px) {
  font-family: $fontawesome;
  content: "\f054";
  font-size: $size;
  font-weight: 900;
  color: $color;
}

@mixin triangleBottomRight($size) {
  content: "";
  position: absolute;
  display: block;
  width: $size+0px;
  height: $size+0px;
  background-color: $primary;
  bottom: -(($size)/2) + 0px;
  right: -(($size)/2) + 0px;
  transform: rotate(45deg);
}

@mixin triangleTopLeft($size) {
  content: "";
  position: absolute;
  display: block;
  width: $size+0px;
  height: $size+0px;
  background-color: $primary;
  top: -($size/2)+ 0px;
  left: -($size/2)+ 0px;
  transform: rotate(45deg);
}

@mixin hover($color, $hover: rgba($color, 0.8)) {
  background-color: $color;

  &:hover {
    background-color: $hover;
  }
}

@mixin aTag {
  color: $primary;
  text-decoration: underline;
  cursor: pointer;
}

@mixin h1Tag {
  color: $text;
  font-family: $montserrat;
  font-size: $base-h1-size;
}

@mixin h2Tag {
  color: $text;
  font-family: $montserrat;
  font-size: $base-h2-size;
}

@mixin h3Tag {
  color: $primary;
  font-family: $montserrat;
  font-weight: 800;
  font-size: $base-h3-size;
  margin: 1em 0 1em;
}

@mixin bodyFont {
  color: $text;
  font-family: $roboto;
  font-weight: 300;
  font-size: $base-text-size;
}