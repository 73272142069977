.Review {
  p {
    font-size: $sm-text;
    margin: $sm-text 0;
  }

  .form-group {
    margin-bottom: 1em;
    display: flex;
    align-items: flex-start;

    .form-check {
      margin-right: 1em;
      justify-content: flex-start;
      display: flex;
      margin-top: 1px;
    }
  }
}