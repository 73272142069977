.sideBar {
  .pricing-details {
    margin-bottom: 40px;
    /* Media ($lg)
    -------------------------------------------------- */
    @media screen and (max-width: $lg) {
      display: none;
    }

    &__mobileHeader {
      display: none;
    }
  }
}

.mobilePricing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .modal-backdrop {
    position: fixed;
  }

  .pricing-details {
    overflow: hidden;
    z-index: 3;
    width: 40%;
    height: auto;
    max-width: 280px;
    position: relative;
    display: none;
    padding: 0;
    transition: max-height 0.3s ease-in-out;

    &.active {
      .pricing-details__mobileHeader {
        &:after {
          content: '\f077';
        }
      }
    }

    .pricing-details__mobileHeader {
      border-top-left-radius: 5px;

      /* Media ($sm)
      -------------------------------------------------- */
      @media screen and (max-width: $sm) {
        border-radius: 0;
      }
    }

    .pricing-details__Wrapper {
      padding: 15px;

      background-color: $white;

      /* Media ($lg)
    -------------------------------------------------- */
      @media screen and (max-width: $lg) {
        // transform: translateY(calc(0% + 50px));
        z-index: 4;
      }
    }

    /* Media ($lg)
    -------------------------------------------------- */
    @media screen and (max-width: $lg) {
      display: block;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      position: absolute;
      top: calc(50% - 25px);
      right: calc((-1) * ((100vw - 100%) / 2));
      max-height: 50px;

      .pricing-details__Wrapper {
        margin-top: 50px;
      }

      &.active {
        max-height: 900px;
      }
    }

    /* Media ($sm)
    -------------------------------------------------- */
    @media screen and (max-width: $sm) {
      margin-right: 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;

      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
    }

    &.active {
      // overflow: visible;
      // position: absolute;
      // height: auto;
      // right: 0;
      // top: 0;
      // padding-bottom: calc(100% + 50px);
      z-index: 4;
      background-color: $white;
    }

    &__mobileHeader {
      height: 50px;
      width: 100%;
      background-color: $primary;
      z-index: 4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      right: 0;
      padding: 0 25px 0 15px;
      color: $white;
      font-weight: bold;

      /* Media ($lg)
      -------------------------------------------------- */
      @media screen and (min-width: $lg) {
        display: none;
      }

      &:after {
        font-family: $fontawesome;
        content: '\f078';
        font-size: 12px;
        font-weight: 900;
        color: $white;
        border-right: 1px solid $white;
        padding-right: 10px;
        height: 60%;
        display: flex;
        align-items: center;

        /* Media ($sm)
        -------------------------------------------------- */
        @media screen and (max-width: $sm) {
          border: none;
        }
      }

      .price {
        /* Media ($sm)
        -------------------------------------------------- */
        @media screen and (max-width: $sm) {
          margin-left: auto;
          margin-right: 50px;
        }
      }
    }

    &__Wrapper {
      /* Media ($lg)
    -------------------------------------------------- */
      @media screen and (max-width: $lg) {
        // transform: translateY(-100%);
        z-index: 4;
      }
    }
  }
}

.Coupon + .pricing-details {
  margin-top: 2em;
}

.pricing-details {
  &__header {
    border-bottom: 2px solid $primary-color;

    h2 {
      margin-bottom: 0.25em;

      i {
        margin-left: 0.25em;
        font-size: 0.75em;
      }
    }
  }

  &__group {
    margin: 0 1em;
    padding: 0.5em 0 0.25em;
    border-bottom: 1px dotted $primary-color;

    &:last-child {
      border-bottom: none;
    }
  }

  &__session {
    font-size: 12px;
  }
  &__item {
    display: flex;
    margin-bottom: 0.5em;

    .label {
      flex-basis: 80%;

      &.bold {
        font-weight: 700;
      }
    }

    .value {
      flex-basis: 20%;
      text-align: right;
      font-weight: 700;
      white-space: nowrap;
    }
  }

  &__footer {
    background-color: $primary-color;
    color: $white;
    font-weight: 700;
    padding: 0.5em 1em;
  }
}
