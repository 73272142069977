.FormBottomNav {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 40px;
  position: relative;

  .prevQuote {
    color: $grey-dk;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    padding: 11px 0;

    i {
      margin-right: 5px;
      font-size: $sm-text;
    }
  }

  .LoaderButton {
  }
  &.hasRequiredToolTip {
    padding-top: 40px !important;

    .ToolTip {
      margin-left: 0;
      z-index: 9;
      position: relative;
      top: calc(-50px);
      left: calc(50% + 9px);
      // float: right;
      border-radius: 100%;
      height: 18px;
      width: 18px;
      display: block;
      border-radius: 100%;
      .ToolTipText {
        @media screen and (max-width: $md) {
          position: absolute;
          right: 0 !important;
          bottom: 30px !important;
          left: auto !important;
        }
      }
    }
    &.noPrevPage {
      .ToolTip {
        left: 120px;
      }
    }
  }
}

.tempNext {
  margin-top: 10px;
  margin-bottom: 10px;
  float: right;
  // background-color: $secondary;
  @include hover($alert, rgba($alert, 0.8));
  color: $white;
  border-radius: 25px;
  padding: 11px 29px;
  line-height: 18px;
  font-size: 14px;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  display: inline-block;
  text-transform: uppercase;
}
