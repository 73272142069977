.ConfirmEmail {
  margin-top: 40px;
  margin-bottom: 25px;
  padding: 0;

  &__header {
    background-color: $alert;
    color: $white;
    padding: 10px;
    font-size: 16px;

    i {
      &.fa-times-circle {
        cursor: pointer;
      }

      &.fa-exclamation-circle {
        margin-right: 10px;
      }
    }
  }

  &__bodyWrapper {
    padding: 10px 15px;
    border-top: none;
    border: 1px solid $alert;
  }

  &__body {
    margin-bottom: 10px;

    span {
      color: $black;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  &__buttons {
    justify-content: space-between;


    i {
      margin-right: 5px;
    }
  }
}