.ThankYou {

  .greenBox {
    .phone {
      font-family: $montserrat;
      font-size: $base-h1-size;
      margin: 10px 0;
    }
  }
}

.viewApplication {
  margin: 25px 0 25px 0px;

  a {
    display: block;
    margin-bottom: 15px;
    text-decoration: none;
    color: $text;
    text-transform: uppercase;

    &:not(.pdf):before {
      @include chevron_right($primary, 12px);
    }

    &.pdf:before {
      font-family: $fontawesome;
      content: "\f1c1";
      font-size: $sm-text;
      font-weight: 400;
      color: $text;
    }

    &:before {
      margin-right: 10px;
      margin-left: 25px;
    }
  }


}