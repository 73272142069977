input[name="FirstName"],
input[name="Last"],
input[name="startDate"] {
  width: 180px;
}

input[name="email"] {
  width: 220px;
}

/* Media ($lg)
-------------------------------------------------- */
@media screen and (min-width: $md) {
  .form-group[data-id="advocisMember"] {
    // display: flex;
    // flex-direction: column;
    // flex-wrap: wrap;
    // height: 80px;
    // justify-content: flex-start;
    &>.form-label{
      margin: 0;
    }
    >* {

      flex: 1 1 20px;
      width: 280px;

      &.PrintFormGroup {
        display: flex;
        width: 66%;
        flex-direction: row;

        >.form-group {
          width: 100%;
          display: flex;
          align-items: flex-end;
          padding-bottom: 5px;

          .form-label {
            margin-bottom: 5px;
            margin-right: 20px;
          }
        }
      }
    }
  }
}