.account {

  &--main {
    .account-info {
      ul {
        padding: 0;

        li {
          &:before {
            display: none;
          }
        }
      }
    }
  }

}