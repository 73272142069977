.ResetPassword {

  h1,
  h2 {
    color: $text;
  }

  .formLink {
    float: right;
    margin-top: 40px;
  }
}