/* =============================================================================
    Policy Card - Account Details Page
============================================================================= */
.policy-section-title {
  // position: absolute;
  // top: -1em;
  margin: 0;
  color: $grey-dk;
}

.policy-card {
  width: 100%;
  border: 1px solid $gray-dk;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  h4 {
    margin-top: 0;
    font-size: 1.143em;
    margin-bottom: 0.15em;
  }

  p {
    margin: 0;
    margin-bottom: 0.5em;
  }

  &__top-bar,
  &__body,
  &__footer {
    padding: 1em;
  }

  &__top-bar {
    background-color: $gray-dk;
    padding: 1em;
    color: $white;
    font-weight: 700;
  }

  &__info-links {
    border-bottom: 2px dotted $gray-lt;
    // padding-bottom: 1em;
    margin-bottom: 1em;

    .download-link {
      color: $gray-dk;
      font-weight: 500;
      text-decoration: none;
      padding-left: 1.25em;
      position: relative;
      text-transform: uppercase;
      margin-bottom: 0.5em;
      display: inline-block;

      &:before {
        position: absolute;
        content: "";
        border-right: 2px solid $primary-color;
        border-top: 2px solid $primary-color;
        width: 0.5em;
        height: 0.5em;
        transform: rotate(45deg);
        left: 0;
        top: 0.25em;
      }
    }
  }

  &__expiry-date {
    span {
      display: block;
      font-weight: 700;
      color: $black;
    }
  }

  &__footer {
    background-color: $grey-lt;
    text-align: center;
  }

  &__header {}
}